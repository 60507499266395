import { render, staticRenderFns } from "./Setup.vue?vue&type=template&id=58025818"
import script from "./Setup.vue?vue&type=script&lang=js"
export * from "./Setup.vue?vue&type=script&lang=js"
import style0 from "./Setup.vue?vue&type=style&index=0&id=58025818&prod&lang=scss&module=true"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.3.9_cache-loader@4.1.0_webpack@4.47.0_webpack-cli@3.3._4p3tzdp6qhkwn5abszlvayyvoe/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports