var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.setup},[(_vm.currentIsSetupSuccess)?_c('router-view'):[(_vm.visibleHeader)?_c('div',{staticClass:"x-wrap x-wrap--full",class:_vm.$style.header},[_c('div',{class:_vm.$style.left},[(_vm.isRegister)?_c('pt-icon',{attrs:{"icon":"icon-pt-experience","iconStyle":{ width: '100px', height: '100px', fill: '#091e42', marginLeft: '16px' }}}):_c('router-link',{attrs:{"to":_vm.backupRouter}},[_c('pt-button',{attrs:{"type":"text","color":"black"}},[_c('pt-icon',{attrs:{"slot":"icon","icon":"pt-icon--back","icon-style":{
                                width: '12px',
                                height: '12px'
                            }},slot:"icon"}),_c('span',[_vm._v(_vm._s(_vm.backupText))])],1)],1)],1),(_vm.isRegister)?_c('pt-button',{class:_vm.$style['btn_toptx'],attrs:{"type":"text","size":"medium","color":"blue"},on:{"click":_vm.gotoHome}},[_vm._v(" "+_vm._s(_vm.$t('data_settings.install_success_btn'))+" ")]):_vm._e()],1):_vm._e(),(_vm.currentIsSetup)?[_c('section',[_c('h1',[_vm._v(_vm._s(_vm.$t('setup.title')))]),_c('h4',[_vm._v(_vm._s(_vm.$t('setup.sub_title')))])]),_c('div',{class:_vm.$style.container},[_c('div',{class:_vm.$style.box},[_c('pt-icon',{attrs:{"icon":"icon-gtm","icon-style":{
                            width: '68px',
                            height: '68px'
                        }}}),_c('p',[_vm._v(_vm._s(_vm.$t('setup.gtm_title')))]),_c('pt-button',{style:({ width: '240px', borderTopLeftRadius: '0', borderBottomLeftRadius: '0' }),attrs:{"loading":_vm.gtmClient.loading},on:{"click":_vm.handleConnectClick}},[_c('pt-icon',{attrs:{"icon":"icon-google-logo","icon-style":{
                                width: '32px',
                                height: '32px',
                                position: 'absolute',
                                border: '1px solid #4285F4',
                                left: '-0'
                            }}}),_vm._v(" "+_vm._s(_vm.$t('setup.connect'))+" ")],1)],1),_c('div',{class:_vm.$style.box},[_c('pt-icon',{attrs:{"icon":"icon-guide","icon-style":{
                            width: '68px',
                            height: '68px'
                        }}}),_c('p',[_vm._v(_vm._s(_vm.$t('setup.install_title')))]),_c('pt-button',{style:({ width: '240px' }),on:{"click":_vm.handleNextBtnClick}},[_vm._v(_vm._s(_vm.$t('setup.go')))])],1)])]:_c('div',{staticClass:"x-wrap x-wrap--full",class:_vm.$style.body},[_c('div',{class:_vm.$style.title},[_vm._v(_vm._s(_vm.$t('data_settings.install_ptx')))]),_c('div',{class:_vm.$style.progress},_vm._l((_vm.stepList),function(step){return _c('div',{key:step.text,class:[_vm.$style.step, _vm.currentStepCode >= step.code && _vm.$style.active]},[_c('router-link',{attrs:{"to":{ name: step.name, query: _vm.$route.query }}},[_c('div',{class:_vm.$style.label},[_vm._v(_vm._s(step.text))])]),(step.code !== 1)?_c('div',{class:_vm.$style.line}):_vm._e()],1)}),0),_c('div',{class:_vm.$style.content},[_c('router-view')],1),_c('div',{class:[_vm.$style.footer, _vm.currentStepCode === 3 && _vm.$style.last]},[(_vm.currentStepCode < 3)?[(_vm.currentStepCode > 1)?_c('pt-button',{class:[_vm.$style.btn_back, ( _obj = {}, _obj[_vm.$style.show] = _vm.currentStepCode < 3 && _vm.currentStepCode > 1, _obj )],attrs:{"size":"medium","type":"text","color":"black","href":""},on:{"click":function($event){return _vm.switchStep(_vm.currentStepCode - 1)}}},[_vm._v(" "+_vm._s(_vm.$t('common.button_back'))+" ")]):_vm._e(),(_vm.currentStepCode !== 3)?_c('pt-button',{class:_vm.$style.next,attrs:{"size":"medium"},on:{"click":function($event){return _vm.switchStep(_vm.currentStepCode + 1)}}},[_vm._v(" "+_vm._s(_vm.$t('common.button_next'))+" ")]):_vm._e()]:_c('pt-button',{attrs:{"size":"medium"},on:{"click":_vm.gotoHome}},[_vm._v(" "+_vm._s(_vm.$t('data_settings.install_success_btn'))+" ")])],2)]),(_vm.visibleGtmClient)?_c('gtm-client',{ref:"gtmClient",on:{"close":_vm.handleClienPopupClose,"init":_vm.handleClientInit,"error":_vm.handClientError,"createFinish":_vm.handleCreateFinish}}):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }