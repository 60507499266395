<template>
    <div :class="$style.setup">
        <router-view v-if="currentIsSetupSuccess"></router-view>

        <template v-else>
            <div class="x-wrap x-wrap--full" :class="$style.header" v-if="visibleHeader">
                <div :class="$style.left">
                    <pt-icon
                        v-if="isRegister"
                        icon="icon-pt-experience"
                        :iconStyle="{ width: '100px', height: '100px', fill: '#091e42', marginLeft: '16px' }"
                    ></pt-icon>
                    <router-link :to="backupRouter" v-else>
                        <pt-button type="text" color="black">
                            <pt-icon
                                slot="icon"
                                icon="pt-icon--back"
                                :icon-style="{
                                    width: '12px',
                                    height: '12px'
                                }"
                            ></pt-icon>
                            <span>{{ backupText }}</span>
                        </pt-button>
                    </router-link>
                </div>
                <pt-button
                    :class="$style['btn_toptx']"
                    type="text"
                    size="medium"
                    color="blue"
                    v-if="isRegister"
                    @click="gotoHome"
                >
                    {{ $t('data_settings.install_success_btn') }}
                </pt-button>
            </div>

            <template v-if="currentIsSetup">
                <section>
                    <h1>{{ $t('setup.title') }}</h1>
                    <h4>{{ $t('setup.sub_title') }}</h4>
                </section>
                <div :class="$style.container">
                    <div :class="$style.box">
                        <pt-icon
                            icon="icon-gtm"
                            :icon-style="{
                                width: '68px',
                                height: '68px'
                            }"
                        ></pt-icon>
                        <p>{{ $t('setup.gtm_title') }}</p>
                        <!-- <a :href="links.gtm" target="_blank">
                            <pt-icon
                                icon="icon-warning"
                                :icon-style="{
                                    fill: '#FF8900',
                                    width: '16px',
                                    height: '16px'
                                }"
                            ></pt-icon>
                            {{ $t('setup.gtm_tips') }}
                        </a>-->
                        <pt-button
                            @click="handleConnectClick"
                            :loading="gtmClient.loading"
                            :style="{ width: '240px', borderTopLeftRadius: '0', borderBottomLeftRadius: '0' }"
                        >
                            <pt-icon
                                icon="icon-google-logo"
                                :icon-style="{
                                    width: '32px',
                                    height: '32px',
                                    position: 'absolute',
                                    border: '1px solid #4285F4',
                                    left: '-0'
                                }"
                            ></pt-icon>
                            {{ $t('setup.connect') }}
                        </pt-button>
                    </div>
                    <div :class="$style.box">
                        <pt-icon
                            icon="icon-guide"
                            :icon-style="{
                                width: '68px',
                                height: '68px'
                            }"
                        ></pt-icon>
                        <p>{{ $t('setup.install_title') }}</p>
                        <pt-button :style="{ width: '240px' }" @click="handleNextBtnClick">{{
                            $t('setup.go')
                        }}</pt-button>
                    </div>
                </div>
            </template>
            <div class="x-wrap x-wrap--full" :class="$style.body" v-else>
                <div :class="$style.title">{{ $t('data_settings.install_ptx') }}</div>
                <div :class="$style.progress">
                    <div
                        :class="[$style.step, currentStepCode >= step.code && $style.active]"
                        v-for="step in stepList"
                        :key="step.text"
                    >
                        <router-link :to="{ name: step.name, query: $route.query }">
                            <div :class="$style.label">{{ step.text }}</div>
                        </router-link>
                        <div :class="$style.line" v-if="step.code !== 1"></div>
                    </div>
                </div>
                <div :class="$style.content">
                    <router-view></router-view>
                </div>
                <div :class="[$style.footer, currentStepCode === 3 && $style.last]">
                    <template v-if="currentStepCode < 3">
                        <pt-button
                            :class="[$style.btn_back, { [$style.show]: currentStepCode < 3 && currentStepCode > 1 }]"
                            @click="switchStep(currentStepCode - 1)"
                            size="medium"
                            type="text"
                            color="black"
                            href
                            v-if="currentStepCode > 1"
                        >
                            {{ $t('common.button_back') }}
                        </pt-button>
                        <pt-button
                            size="medium"
                            :class="$style.next"
                            @click="switchStep(currentStepCode + 1)"
                            v-if="currentStepCode !== 3"
                        >
                            {{ $t('common.button_next') }}
                        </pt-button>
                    </template>
                    <pt-button size="medium" v-else @click="gotoHome">
                        {{ $t('data_settings.install_success_btn') }}
                    </pt-button>
                </div>
            </div>

            <gtm-client
                ref="gtmClient"
                v-if="visibleGtmClient"
                @close="handleClienPopupClose"
                @init="handleClientInit"
                @error="handClientError"
                @createFinish="handleCreateFinish"
            ></gtm-client>
        </template>
    </div>
</template>

<script>
import GtmClient from './GtmClient';
import roleUtils from '@/common/utils/role';
import { mapGetters } from 'vuex';

export default {
    name: 'Setup',

    data() {
        const stepList = [
            {
                code: 1,
                name: 'CodeOverview',
                text: this.$t('data_settings.install_basic')
            },
            {
                code: 2,
                name: 'SetEvents',
                text: this.$t('data_settings.install_event')
            },
            {
                code: 3,
                name: 'IdentifyUsers',
                text: this.$t('data_settings.install_user')
            }
            // {
            //     code: 4,
            //     name: 'CodeVerify',
            //     text: this.$t('data_settings.install_verify')
            // }
        ];

        return {
            activePart: '',
            code: 'Code',
            checkClicked: false,
            stepList,
            visibleGtmClient: false,

            gtmClient: {
                loading: false,
                init: false,
                error: null
            }
        };
    },

    computed: {
        ...mapGetters('main', ['profileInfo']),

        sid() {
            return this.profileInfo.sid;
        },

        backupText() {
            const { redirect } = this.$route.query || {};
            return (redirect && this.$t('common.button_back')) || this.$t('data_settings.back_to_data_setting');
        },

        backupRouter() {
            const { redirect } = this.$route.query || {};
            return redirect || { name: 'DataSettings' };
        },

        isRegister() {
            const { register } = this.$route.query || {};
            return Boolean(register);
        },

        currentStepCode() {
            const step = this.stepList.find(item => item.name === this.$route.name);
            return (step || this.stepList[0]).code;
        },

        currentIsSetup() {
            return this.$route.name === 'Setup';
        },

        currentIsSetupSuccess() {
            return this.$route.name === 'SetupSuccess';
        },

        currentIsInstall() {
            return ['CodeOverview', 'SetEvents', 'IdentifyUsers', 'CodeVerify'].includes(this.$route.name);
        },

        visibleHeader() {
            return this.isRegister || !this.currentIsSetup;
        }
    },

    provide() {
        return {
            codeInstall: this
        };
    },

    methods: {
        handleClienPopupClose() {
            this.visibleGtmClient = false;
            this.$set(this.gtmClient, 'loading', false);
        },

        handleConnectClick() {
            this.visibleGtmClient = true;
            this.$set(this.gtmClient, 'loading', true);
            const waitMaxTime = 30 * 1000;
            let waitTime = 0;
            const waitClientInit = setInterval(() => {
                waitTime += 50;
                const { init, error } = this.gtmClient;
                if (init || error || waitTime > waitMaxTime) {
                    clearInterval(waitClientInit);
                    init && this.$refs.gtmClient && this.$refs.gtmClient.handleSigninClick();
                    waitTime > waitMaxTime && this.handClientError('timeout');
                }
            }, 50);
        },

        handleNextBtnClick() {
            this.changeRouter('CodeOverview');
        },

        handleClientInit() {
            this.gtmClient.init = true;
        },

        handClientError(errType) {
            console.log('errType', errType);
            this.gtmClient.error = errType;
            this.$set(this.gtmClient, 'loading', false);
            ['auth', 'fetchAccounts'].includes(errType) ||
                this.$message.error(this.$t(errType === 'createTag' ? 'setup.client_error' : 'setup.connect_fail'));
        },

        handleCreateFinish() {
            this.changeRouter('SetupSuccess');
            this.$set(this.gtmClient, 'loading', false);
        },

        handleBack() {
            this.$router.go(-1);
        },

        changeRouter(routerName, params = {}) {
            this.$router.push({
                name: routerName,
                params: Object.assign({}, this.$route.params, params),
                query: this.$route.query
            });
        },

        gotoHome() {
            roleUtils.changeRouterToCampaignReport();
        },

        switchStep(step) {
            if (step > 0) {
                this.$router.push({
                    name: this.stepList[step - 1].name,
                    query: this.$route.query
                });
            }
        }
    },
    components: {
        GtmClient
    }
};
</script>

<style lang="scss" module>
@import '@/styles/import.scss';

.setup {
    position: relative;

    .header {
        display: flex;
        height: 80px;
        align-items: center;
        position: relative;
        justify-content: justify;

        .left {
            flex: 1;
        }
    }

    section {
        padding-top: 50px;
        margin: 70px 0 32px;
        text-align: center;

        h1 {
            color: $pt-black-600;
            font-size: 24px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 33px;
        }

        h4 {
            color: $pt-black-600;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 19px;
            margin-top: 12px;
        }
    }

    .container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 50px 10px 0;

        & > svg {
            flex: 0 0 68px;
        }

        a {
            color: $pt-black-600;
            margin: -14px 0 10px;
        }

        &:first-child {
            p {
                margin-top: 14px;
            }
        }

        @include media-md {
            flex-direction: column;
        }

        @include media-sm {
            width: 80%;
            margin: 0 auto 44px;
        }

        .box {
            width: 401px;
            height: 301px;
            padding: 56px 0 44px;
            border: 1px solid $pt-black-40;
            background-color: $pt-white;
            display: flex;
            align-items: center;
            display: flex;
            flex-direction: column;
            margin: 0 22px 40px;

            a {
                color: $pt-black-600;
                margin: -14px 0 10px;
                display: flex;
                align-items: center;
                text-decoration: underline;

                svg {
                    margin-right: 4px;
                }

                &:hover {
                    text-decoration: none;
                }
            }

            p {
                height: 52px;
                color: $pt-black-600;
                font-size: 16px;
                line-height: 22px;
                text-align: center;
                margin: 32px 0 24px;
                padding: 0px 30px;
                @include max-line(2);
            }
        }

        .icon {
            width: 68px;
            height: 68px;
        }

        p {
            height: 52px;
            color: $pt-black-600;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
            margin: 16px 0 24px;
            padding: 0px 30px;
            @include max-line(2);
        }
    }

    .body {
        flex: 1;
        margin: auto;
        padding-bottom: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .title {
            font-size: 20px;
            color: $pt-black-600;
            line-height: 27px;
            text-align: center;
            font-weight: 600;
            margin: 20px 0 40px;
        }

        .progress {
            display: flex;
            // width: 100%;
            height: 36px;
            .step {
                position: relative;
                //  flex-grow: 1;
                padding-left: 100px;
                .label {
                    cursor: pointer;
                    color: $pt-green-60;
                    width: fit-content;
                    height: 36px;
                    border-radius: 18px;
                    text-align: center;
                    border: 1px solid $pt-black-40;
                    background-color: $pt-white;
                    padding: 8px 20px;
                    white-space: nowrap;
                    z-index: 1;
                }
                &:first-child {
                    padding-left: 0;
                }
                .line {
                    width: 100px;
                    top: 15px;
                    height: 4px;
                    position: absolute;
                    border: 1px solid $pt-black-40;
                    border-right: 0;
                    border-left: 0;
                    background-color: $pt-white;
                    left: 0;
                }
                &.active {
                    color: $pt-green-60;
                    .label {
                        background-color: $pt-black-40;
                        border: 1px solid $pt-black-40;
                    }
                    .line {
                        background-color: $pt-black-40;
                        border: 1px solid $pt-black-40;
                    }
                }
            }
        }

        .footer {
            height: 36px;
            width: 650px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .btn_back {
                transform: translateX(-16px);
                opacity: 0;
                pointer-events: none;
                cursor: default;
                &.show {
                    opacity: 1;
                    pointer-events: all;
                    cursor: pointer;
                }
            }
            &.last {
                /* justify-content: center; */
            }
        }

        :global(.flat) {
            margin-top: 16px;
        }
    }
}
</style>
